<template>
  <b-modal
    id="modal-view-demand"
    size="md"
    title="Xem nhu cầu tuyển dụng"
    centered
    hide-footer
  >
    <div
      v-for="(item, index) in modalData"
      :key="index"
      class="d-flex flex-wrap justify-content-between mb-1 flex-wrap"
    >
      <span>{{ item.text }}</span>
      <span>{{ item.value }}</span>
    </div>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue'

export default {
  components: {
    BModal,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    modalData() {
      return [
        {
          text: 'Số lao động trình độ đại học cần tuyển',
          value: this.data.amountUniversity,
        },
        {
          text: 'Số lao động trình độ cao đẳng cần tuyển',
          value: this.data.amountCollege,
        },
        {
          text: 'Số lao động trình độ trung cấp cần tuyển',
          value: this.data.amountIntermediate,
        },
        {
          text: 'Số lao động trình độ sơ cấp cần tuyển',
          value: this.data.amountOverPrimary,
        },
        {
          text: 'Số lao động trình độ chưa qua đào tạo cần tuyển',
          value: this.data.amountNoneTraining,
        },
      ]
    },
  },
}
</script>
