<template>
  <div>
    <div class="page-container mb-2">
      <h4 class="mb-2">
        Bộ lọc
      </h4>
      <b-row>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Doanh nghiệp"
            label-for="year"
          >
            <v-select
              v-model="queryParams.bussinessId"
              :reduce="(name) => name.id"
              label="name"
              :options="comboboxBusiness || []"
              placeholder="Doanh nghiệp"
              @input="filterBusiness"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          md="2"
        >
          <b-form-group>
            <label>Từ ngày</label>
            <date-picker
              v-model="queryParams.startTime"
              :disabledInput="true"
              @input="filterBusiness"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          md="2"
        >
          <b-form-group>
            <label>Đến ngày</label>
            <date-picker
              v-model="queryParams.endTime"
              :disabledInput="true"
              @input="filterBusiness"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div
      id="users"
      class="page-container-table"
    >
      <button-all-header
        :contentBtnAdd="'Thêm nhu cầu'"
        :showBtnMultiDelete="false"
        :hideImportFile="false"
        :hideExportFile="false"
        :hideDelete="false"
        :hideAdd="false"
        :hideDowload="false"
        @search="search($event)"
      />
      <vue-good-table
        ref="user-table"
        class="mh-60"
        style-class="vgt-table"
        :columns="columns"
        :rows="dataList || []"
        :select-options="{
          enabled: true,
          vertialAlignTop: true,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true,
          selectAllByGroup: true,
        }"
      >

        <template
          slot="table-column"
          slot-scope="props"
        >
          <span class="text-nowrap">
            {{ props.column.label }}
          </span>
        </template>

        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'action'">
            <span
              class="ml-2"
              @click="getDemand(props.row.id)"
            >
              <feather-icon
                v-b-tooltip.hover.top="'Xem tài liệu'"
                icon="EyeIcon"
                size="18"
                class="text-body"
              />
            </span>
            <span
              class="ml-2"
              @click="$router.push({ name: 'recommend-employment-user', params: {id: props.row.id} })"
            >
              <feather-icon
                v-b-tooltip.hover.top="'Thêm người lao động'"
                icon="PlusCircleIcon"
                size="18"
                class="text-body"
              />
            </span>
          </span>
          <span v-else-if="props.column.field === 'dateRecruit'">
            <span v-if="props.row.dateRecruit">{{ props.row.dateRecruit | formatDate }}</span>
            <span v-else>-</span>
          </span>
          <span v-else-if="props.column.field === 'dateExpired'">
            <span v-if="props.row.dateExpired">{{ props.row.dateExpired | formatDate }}</span>
            <span v-else>-</span>
          </span>
        </template>
      </vue-good-table>

      <my-pagination
        :totalItems="totalRecord"
        @pageClick="handlePageClick"
      />
    </div>
    <modal-view-demand :data="businessDemand" />
  </div>
</template>

<script>
import {
  VBTooltip,
  BFormGroup,
  BRow,
  BCol,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import Search from '@/components/search/Search.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import DatePicker from '@/components/datepicker/DatePicker.vue'
import ModalViewDemand from './components/ModalViewDemand.vue'
import store from '../store'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'

export default {
  components: {
    VueGoodTable,
    MyPagination,
    Search,
    ConfirmModal,
    VBTooltip,
    BFormGroup,
    VSelect,
    BRow,
    BCol,
    DatePicker,
    ModalViewDemand,
    ButtonAllHeader,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      queryParams: {
        bussinessId: null,
        startTime: null,
        endTime: null,
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      columns: [
        {
          label: 'Doanh nghiệp',
          field: 'bussiness',
          sortable: false,
        },
        {
          label: 'Ngày gửi nhu cầu tuyển dụng',
          field: 'dateRecruit',
          sortable: false,
        },
        {
          label: 'Ngày hết hạn',
          field: 'dateExpired',
          sortable: false,
        },
        {
          label: 'Chức năng',
          field: 'action',
          sortable: false,
          width: '175px',
        },
      ],
      dataList: [],
      totalRecord: 0,
      comboboxBusiness: [],
      businessDemand: {
        amountCollege: 0,
        amountIntermediate: 0,
        amountNoneTraining: 0,
        amountOverPrimary: 3,
        amountUniversity: 0,
      },
    }
  },
  created() {
    this.getListBusiness()
    this.getComboboxBusiness()
  },
  methods: {
    async getComboboxBusiness() {
      const { data } = await store.getComboboxBusiness()
      this.comboboxBusiness = data
    },
    async getListBusiness() {
      this.$showLoading()
      const { data } = await store.getListBusiness(this.queryParams)
      if (data) {
        this.dataList = data.pageLists
        this.totalRecord = data.totalRecord
      }
      this.$hideLoading()
    },
    handlePageClick(pageNumber, pageSize) {
      this.queryParams = {
        ...this.queryParams,
        pageNumber,
        pageSize,
      }
      this.getListBusiness()
    },
    search(val) {
      this.queryParams = {
        ...this.queryParams,
        pageNumber: 1,
        key: val,
      }
      this.getListBusiness()
    },
    async filterBusiness() {
      if (this.queryParams.startTime && this.queryParams.endTime) {
        const startTime = new Date(this.queryParams.startTime)
        const endTime = new Date(this.queryParams.endTime)
        if (startTime - endTime > 0) {
          this.$root.$bvToast.toast('Thời gian bắt đầu phải lớn hơn thời gian kết thúc', {
            title: 'Thông báo',
            variant: 'warning',
            toaster: this.$toastPosition,
            solid: true,
          })
          return
        }
      }
      this.getListBusiness()
    },
    async getDemand(id) {
      const data = await store.getDemand(id)
      this.businessDemand = data
      this.$bvModal.show('modal-view-demand')
    },
  },
}
</script>
