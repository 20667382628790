var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"page-container mb-2"},[_c('h4',{staticClass:"mb-2"},[_vm._v(" Bộ lọc ")]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Doanh nghiệp","label-for":"year"}},[_c('v-select',{attrs:{"reduce":function (name) { return name.id; },"label":"name","options":_vm.comboboxBusiness || [],"placeholder":"Doanh nghiệp"},on:{"input":_vm.filterBusiness},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}]),model:{value:(_vm.queryParams.bussinessId),callback:function ($$v) {_vm.$set(_vm.queryParams, "bussinessId", $$v)},expression:"queryParams.bussinessId"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('b-form-group',[_c('label',[_vm._v("Từ ngày")]),_c('date-picker',{attrs:{"disabledInput":true},on:{"input":_vm.filterBusiness},model:{value:(_vm.queryParams.startTime),callback:function ($$v) {_vm.$set(_vm.queryParams, "startTime", $$v)},expression:"queryParams.startTime"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('b-form-group',[_c('label',[_vm._v("Đến ngày")]),_c('date-picker',{attrs:{"disabledInput":true},on:{"input":_vm.filterBusiness},model:{value:(_vm.queryParams.endTime),callback:function ($$v) {_vm.$set(_vm.queryParams, "endTime", $$v)},expression:"queryParams.endTime"}})],1)],1)],1)],1),_c('div',{staticClass:"page-container-table",attrs:{"id":"users"}},[_c('button-all-header',{attrs:{"contentBtnAdd":'Thêm nhu cầu',"showBtnMultiDelete":false,"hideImportFile":false,"hideExportFile":false,"hideDelete":false,"hideAdd":false,"hideDowload":false},on:{"search":function($event){return _vm.search($event)}}}),_c('vue-good-table',{ref:"user-table",staticClass:"mh-60",attrs:{"style-class":"vgt-table","columns":_vm.columns,"rows":_vm.dataList || [],"select-options":{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true,
      }},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(props.column.label)+" ")])]}},{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',[_c('span',{staticClass:"ml-2",on:{"click":function($event){return _vm.getDemand(props.row.id)}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Xem tài liệu'),expression:"'Xem tài liệu'",modifiers:{"hover":true,"top":true}}],staticClass:"text-body",attrs:{"icon":"EyeIcon","size":"18"}})],1),_c('span',{staticClass:"ml-2",on:{"click":function($event){return _vm.$router.push({ name: 'recommend-employment-user', params: {id: props.row.id} })}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Thêm người lao động'),expression:"'Thêm người lao động'",modifiers:{"hover":true,"top":true}}],staticClass:"text-body",attrs:{"icon":"PlusCircleIcon","size":"18"}})],1)]):(props.column.field === 'dateRecruit')?_c('span',[(props.row.dateRecruit)?_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(props.row.dateRecruit)))]):_c('span',[_vm._v("-")])]):(props.column.field === 'dateExpired')?_c('span',[(props.row.dateExpired)?_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(props.row.dateExpired)))]):_c('span',[_vm._v("-")])]):_vm._e()]}}])}),_c('my-pagination',{attrs:{"totalItems":_vm.totalRecord},on:{"pageClick":_vm.handlePageClick}})],1),_c('modal-view-demand',{attrs:{"data":_vm.businessDemand}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }